<template>
  <div class="sypt_box">
    <div class="banner_center">
      <div class="banner_safe">
            <div class="banner_word">
                <p>实验平台</p>
                <p>学以致用，云上实操，真实云环境中提升云上技术能力</p>
            </div>
      </div>
    </div>
    <div class="sypt_iframe">
      <iframe
        v-show="completeLoad"
        :src="src"
        frameborder="0"
        scrolling="auto"
        class="iframe"
        id="bdIframe"
        height="960"
        allowfullscreen="true"
      ></iframe>
    </div>
  </div>
</template>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
.sypt_box {
  width: 100%;
  margin: 0 auto;
  min-width: 1200px;
  max-width: 1920px;
  min-height: 900px;  
  background: #f1f3f7;
  height: 100%;
  overflow: hidden;
}
.banner_center {
  width: auto;
  height: 340px;
  background: url("~@/assets/sypt_img/sypt_bj.png") center center no-repeat;
  background-size: cover;
  overflow: hidden;
}
.iframe {
  width: 100%;
  margin-bottom: 20px;
}
.sypt_iframe {
  width: 100%;
  height: 100%
}
.banner_safe{
  width: 1200px;
  margin: 0 auto;
  height: 340px;
  overflow: hidden;


}
.banner_word{
  height: 136px;
  width: 590px;
  margin-top: 95px;
  margin-left: 4%;

}
.banner_word p:first-child{
  width: 288px;
height: 87px;
font-size: 62px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #000000;
line-height: 87px;
}
.banner_word p:last-child{
width: 590px;
height: 33px;
font-size: 24px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #000000;
line-height: 33px;
margin-top: 16px;
opacity: 0.7;
}
</style>
<script>
export default {
  data() {
    return {
      src: "https://huixinic.gaoxiaobang.com/#/huixin/index",
      completeLoad: "",
    };
  },
  methods: {},
  mounted() {
    var _this = this;
    const oIframe = document.getElementById("bdIframe");
   //   const divv=document.querySelector('.sypt_box')
   // if(!_this.completeLoad){
   //      divv.style.height = 1000 + "px";

   // }else{
   //   divv.style.height = 100+'%'
   // }
    if (oIframe.attachEvent) {
      oIframe.attachEvent("onload", function () {
        _this.completeLoad = true;
      });
    } else {
      oIframe.onload = function () {
        _this.completeLoad = true;
      };
    }

    // window.addEventListener("message", function (e) {
    //   if (e.data < 1000) {
    //     oIframe.style.height = 1000 + "px";
    //   } else {
    //     oIframe.style.height = e.data + "px";
    //   }
    // });
  },

  watch: {},
};
</script>